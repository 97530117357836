import BaseControl from '../base-control/base-control'
import $ from 'jquery'

import '../style/background-control.scss'
import Layout from '../templates/background-readonly-control.tpl'

class BackgroundControl extends BaseControl {
  constructor (controller, model) {
    super(controller, () => Layout, model, 'background')
    this.lazyImageSelector = '.kv-background-inner, video'
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    // Fix for iOS which doesn't support fixed
    if (data.fixed === true) {
      const iOS =
        typeof window !== 'undefined' &&
        window.navigator &&
        !!window.navigator.platform &&
        /iPad|iPhone|iPod/.test(window.navigator.platform)
      if (iOS === true) {
        element.querySelector('.kv-background-inner').style.backgroundAttachment = ''
      }
    }

    if (data.parallax === true) {
      const $parallax = $('.kv-parallax-container', element)
      if ($parallax.simpleParallax) {
        $parallax.simpleParallax({ scale: '1.50' })
      }
    }

    if (data.contain === true) {
      element.querySelector('.kv-background-inner').style.backgroundSize = 'contain'
    }
  }

  getChildControls () {}
}

export default BackgroundControl
