import './style.scss'
import GlobalProps from 'editor/controls/handlebars/global-props'

class BlurInImageMixin {
  bindLayout (el, data) {
    const imageQuery = '*[data-src-normal]'
    const images = [...el.querySelectorAll(imageQuery)]
    this.initializeLazyImages(images)
  }

  initializeLazyImages = images => {
    // Try to use native lazy loader
    if (!('IntersectionObserver' in window)) {
      Array.from(images).forEach(image => {
        this.prepareBlurIn(image)
        this.blurIn(image)
      })
    } else {
      Array.from(images).forEach(image => this.prepareBlurIn(image))
      const isOnboarding = document.querySelector('.welcome')
      this.observer = new window.IntersectionObserver(entries => this.onIntersection(entries), {
        rootMargin: isOnboarding ? '5000px 0px' : '200px 0px', // start to load image when it's 200px below bottom, or it's  onboarding
        threshold: 0.01
      })

      images.forEach(image => {
        this.observer.observe(image)
      })
    }
  }

  onIntersection = entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        this.observer.unobserve(entry.target)
        this.blurIn(entry.target)
      }
    })
  }

  isRetina = () => {
    // return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    if (window.matchMedia) {
      const mq = window.matchMedia(
        'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)'
      )
      return (mq && mq.matches) || window.devicePixelRatio > 1
    }
  }

  prepareBlurIn (imageEl) {
    if (imageEl.dataset.blurIn) {
      return
    }
    imageEl.dataset.blurIn = true
    imageEl.classList.add('blur-in-image')

    // Append preloader
    const preloader = (this.preloader = document.createElement('DIV'))
    preloader.style.cssText = imageEl.style.cssText
    preloader.classList.add('blur-in-preloader')
    imageEl.appendChild(preloader)
  }

  blurIn (imageEl) {
    const lowResUrl = imageEl.dataset.src
    const normalResUrl = imageEl.dataset.srcNormal
    const retinaResUrl = imageEl.dataset.srcRetina
    const preloader = imageEl.querySelector('.blur-in-preloader')

    const doPreloadNormal = () => {
      // Remove loading icon
      imageEl.classList.remove('loader')

      // Start loading normal resolution
      this.imagePreload(normalResUrl, () => {
        this.setImageSource(imageEl, normalResUrl)
        // mark preloader as loaded to start fade
        preloader.classList.add('loaded')

        setTimeout(() => {
          // Preloader no longer needed
          preloader.remove()
          imageEl.classList.remove('blur-in-image')

          // Retina upgrade for image
          if (this.isRetina() && retinaResUrl) {
            this.imagePreload(retinaResUrl, () => {
              this.setImageSource(imageEl, normalResUrl, retinaResUrl)
            })
          }
        }, 1200)
      })
    }

    if (lowResUrl && preloader) {
      this.imagePreload(lowResUrl, () => {
        this.setPreloaderSource(preloader, lowResUrl)
        doPreloadNormal()
      })
    } else {
      doPreloadNormal()
    }
  }

  imagePreload (url, callback) {
    // eslint-disable-next-line no-undef
    const loader = new Image()
    loader.onload = () => {
      GlobalProps._loadedImages[url] = true
      callback(url)
    }
    loader.onerror = () => {
      console.warn('Could not load image ', url)
    }

    loader.src = url
  }

  setImageSource (el, src, retinaSrc = null) {
    const isImg = el.tagName === 'IMG'
    if (isImg) {
      el.src = src
      if (retinaSrc) {
        el.srcSet = `${retinaSrc} 2x`
      }
    } else {
      el.style.backgroundImage = `url(${src})`
      if (retinaSrc) {
        el.style.backgroundImage = `url(${src});`
        el.style.backgroundImage = `-webkit-image-set( url('${src}') 1x, url('${retinaSrc}') 2x );`
      }
    }
  }

  setPreloaderSource (el, src) {
    const isImg = el.parentElement.tagName === 'IMG'

    if (isImg) {
      el.src = src
    } else {
      el.style.backgroundImage = `url(${src})`
    }
  }
}
export default BlurInImageMixin
